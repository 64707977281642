<template>
  <div :class="{ index: true, 'max-index': !$store.state.sidebarStatus }">
    <!-- 顶部 -->
    <div class="index-top">
      <div class="index-top-left">
        <div class="index-cont-title">
          <div class="index-cont-title-left">
            <img src="../../assets/img/views/topqq.png" />
            <span>舆情走势</span>
          </div>
          <div class="index-cont-title-right">
            <div :class="{ 'index-cont-title-card': true, active: cardActive1 == val.type, noClick: disables }"
              v-for="(val, index) in tabCard" :key="index" @click="tabActive1(val)">
              {{ val.name }}
            </div>
          </div>
        </div>
        <div class="trend-title">
          <div>
            <span>总量</span>
            <span class="box-color-box">
              <span class="box-color obverse"></span>
              <span>正</span>
            </span>
            <span class="box-color-box">
              <span class="box-color neutral"></span>
              <span>中</span>
            </span>
            <span class="box-color-box">
              <span class="box-color negative" style="background-color:#ff0015"></span>
              <span>负</span>
            </span>
          </div>
          <div>
            <span>增量</span>
            <span class="box-color-box">
              <span class="box-color-line obverse"></span>
              <span>正</span>
            </span>
            <span class="box-color-box">
              <span class="box-color-line neutral"></span>
              <span>中</span>
            </span>
            <span class="box-color-box">
              <span class="box-color-line negative" style="background-color:#ff0015"></span>
              <span>负</span>
            </span>
          </div>
        </div>
        <div v-if="haveData" v-loading="loading" class="container index-top-cont" id="echarts1"></div>
        <div v-if="noData" class="analyse-container">
          <div class="analyse-container-img">
            <img src="../../assets/img/views/analyse-img.png" />
            <p>暂无数据</p>
          </div>
        </div>
      </div>
      <div class="index-top-right">
        <div class="index-cont-title">
          <div class="index-cont-title-left">
            <img src="../../assets/img/views/topqq.png" />
            <span>预警信息</span>
          </div>
          <div class="index-cont-title-right">
            <div :class="{ 'index-cont-title-card': true, active: cardActive4 == val.type, }"
              v-for="(val, index) in tabCardMessage" :key="index" @click="tabActive4(val)">
              {{ val.name }}
            </div>
          </div>
        </div>
        <div class="container index-top-cont">
          <div style="height: 100%" v-loading="loadingUl">
            <ul class="index-ul" v-if="warning.length != 0">
              <li @click="handelPush(val)" class="index-ul-li" v-for="(val, index) in warning.slice(0, 6)" :key="index">
                <div class="index-ul-li-box">
                  <img src="../../assets/img/views/red.png" v-if="val.tonalState == 2 && cardActive4 == 1" />
                  <img src="../../assets/img/views/zhong.png" v-if="val.tonalState == 1 && cardActive4 == 1" />
                  <img src="../../assets/img/views/zheng.png" v-if="val.tonalState == 0 && cardActive4 == 1" />
                  <img src="../../assets/img/views/min.png" v-if="cardActive4 == 2 && val.sensitives == 1" />
                  <img src="../../assets/img/views/fei.png" v-if="cardActive4 == 2 && val.sensitives == 0" />
                  <img src="../../assets/img/views/nei.png" v-if="
                    (cardActive4 == 0 && val.montype == 0) || val.montype == 1
                  " alt />
                  <img src="../../assets/img/views/wai2.png" v-if="
                    (cardActive4 == 0 && val.montype == 2) || val.montype == 3
                  " alt />
                  <img src="../../assets/img/views/pin.png" v-if="
                    (cardActive4 == 0 && val.montype == 5) || val.montype == 4
                  " alt />
                  <div class="index-ul-li-title">{{ val.title }}</div>
                  <div class="comment-weidu">
                    <img v-if="val.isRead == '1'" src="../../assets/img/views/greenmessage.png" />
                    <img v-else src="../../assets/img/views/redmessage.png" />
                    <span :class="{ 'yidu': val.isRead == '1' }">
                      {{ val.isRead == "1" ? "已读" : "未读" }}
                    </span>
                  </div>
                </div>

                <div v-if="val.time" class="index-ul-li-time">
                  {{ val.time }}
                </div>
                <div class="index-ul-li-time" v-else></div>
              </li>
            </ul>
            <div class="analyse-container" v-else>
              <div class="analyse-container-img">
                <img src="../../assets/img/views/analyse-img.png" />
                <p>暂无数据</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 中间 -->
    <div class="index-center">
      <div class="index-top-left">
        <div class="index-cont-title">
          <div class="index-cont-title-left">
            <img src="../../assets/img/views/topqq.png" />
            <span>调性分布</span>
          </div>
          <div class="index-cont-title-right">
            <div :class="{
              'index-cont-title-card': true,
              active: cardActive2 == val.type,
              noClick: disabless,
            }" v-for="(val, index) in tabCard" :key="index" @click="tabActive2(val)">
              {{ val.name }}
            </div>
          </div>
        </div>
        <div class="index-center-cont-title">
          <div>
            <span class="index-center-circular circularblue"></span>
            <span>全国</span>
          </div>
          <div>
            <span class="index-center-circular circulartwo"></span>
            <span>省级</span>
          </div>
          <div>
            <span class="index-center-circular circularthree"></span>
            <span>市级</span>
          </div>
          <div>
            <span class="index-center-circular circularfour"></span>
            <span>区县级</span>
          </div>
          <div>
            <span class="index-center-circular circularfive"></span>
            <span>其他</span>
          </div>
        </div>
        <div style="display: flex;height: 352px;margin-top: 16px;overflow: hidden;" class="container"
          v-loading="loading1">
          <div v-if="this.tonalityAll.length != 0" style="width: 34%; height: 352px; margin: 0" class="index-center-cont"
            id="echarts2one"></div>
          <div class="analyse-container" v-else style="height: 352px; width: 34%; margin: 0">
            <div class="analyse-container-img">
              <img style="width: 100%" src="../../assets/img/views/analyse-img.png" />
              <p>全部</p>
            </div>
          </div>
          <div v-if="this.tonalityFm.length != 0" style="width: 34%; height: 352px; margin: 0" class="index-center-cont"
            id="echarts2two"></div>
          <div class="analyse-container" v-else style="height: 352px; width: 34%; margin: 0">
            <div class="analyse-container-img">
              <img style="width: 100%" src="../../assets/img/views/analyse-img.png" />
              <p>负面</p>
            </div>
          </div>
          <div v-if="this.tonalityOr.length != 0" style="width: 34%; height: 352px; margin: 0" class="index-center-cont"
            id="echarts2three"></div>
          <div class="analyse-container" v-else style="height: 352px; width: 34%; margin: 0">
            <div class="analyse-container-img">
              <img style="width: 100%" src="../../assets/img/views/analyse-img.png" />
              <p>非负面(正面+中性)</p>
            </div>
          </div>
        </div>
      </div>
      <div class="index-top-right">
        <div class="index-cont-title">
          <div class="index-cont-title-left">
            <img src="../../assets/img/views/topqq.png" />
            <span>媒体分布</span>
          </div>
          <div class="index-cont-title-right">
            <div :class="{
              'index-cont-title-card': true,
              active: cardActive3 == val.type,
              noClick: disablesss,
            }" v-for="(val, index) in tabCard" :key="index" @click="tabActive3(val)">
              {{ val.name }}
            </div>
          </div>
        </div>
        <div v-loading="loading2">
          <div v-if="mediaName.length != 0 || justData.length != 0 || zhongData.length != 0 || negativeData.length != 0"
            class="container index-center-cont" id="echarts3"></div>
          <div class="analyse-container" v-else style="height:352px;width:100%;margin-top:16px;border-radius:8px;">
            <div class="analyse-container-img">
              <img src="../../assets/img/views/analyse-img.png" />
              <p>暂无数据</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="index-bottom">
      <div class="index-bottom-left">
        <div class="index-cont-title">
          <div class="index-cont-title-left">
            <img src="../../assets/img/views/topqq.png" />
            <span>话题统计</span>
          </div>
          <div class="index-cont-title-right">
            <div @click="handelTopic" class="index-cont-title-more">
              <span class="index-more-span">全部话题</span>
              <img src="../../assets/img/views/more.png" />
            </div>
          </div>
        </div>
        <div v-loading="loadingTopic">
          <div class="container index-bottom-cont" v-if="conversation.length > 0">
            <ul class="index-bottom-ul">
              <li class="index-bottom-ul-li" @click="handle(val)" v-for="(val, index) in conversation" :key="index">
                <div style="display: flex">
                  <img src="../../assets/img/views/nei.png" v-if="val.topicType == 0" />
                  <img src="../../assets/img/views/pin.png" v-else />
                  <div class="index-bottom-ul-li-title" style="margin-left: 5px">
                    {{ val.topicName }}
                  </div>
                  <div class="index-bottom-ul-li-time">
                    {{ val.startTime }} -
                    {{ val.isDeepUpdate == 1 ? val.upateTime : "当前" }}
                  </div>
                </div>

                <div class="index-bottom-ul-box" style="width: 80px">
                  <span class="index-bottom-ul-li-sum">
                    {{ val.totalInformation }}
                  </span>
                  <div>
                    <img src="../../assets/img/views/up.png" />
                  </div>
                  <span class="index-bottom-ul-li-sum">
                    {{ val.todayInformation }}
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <div class="analyse-container" v-else style="height: 477px">
            <div class="analyse-container-img">
              <img src="../../assets/img/views/analyse-img.png" />
              <p>暂无数据</p>
            </div>
          </div>
        </div>
      </div>
      <div class="index-bottom-center">
        <div class="index-cont-title">
          <div class="index-cont-title-left">
            <img src="../../assets/img/views/topqq.png" />
            <span>监测方案</span>
          </div>
          <div class="index-cont-title-right">
            <div @click="handelProgramme" class="index-cont-title-more">
              <span class="index-more-span">全部方案</span>
              <img src="../../assets/img/views/more.png" />
            </div>
          </div>
        </div>
        <div v-loading="loadngSupervise">
          <div class="container index-bottom-cont" v-if="monitorList.length > 0">
            <ul class="index-bottom-ul supervise">
              <li class="index-bottom-ul-li" @click="handelProgrammeDetail(val)" v-for="(val, index) in monitorList"
                :key="index">
                <div style="display: flex">
                  <img src="../../assets/img/views/nei.png" v-if="val.projectType == 0" />
                  <img src="../../assets/img/views/pin.png" v-if="val.projectType == 2" />
                  <img src="../../assets/img/views/wai2.png" v-if="val.projectType == 1" />
                  <div class="index-bottom-ul-li-title">
                    {{ val.projectName }}
                  </div>
                </div>

                <div class="index-bottom-ul-box">
                  <span class="index-bottom-ul-li-sum">
                    {{ val.monitorTotal }}
                  </span>
                  <div>
                    <img src="../../assets/img/views/up.png" />
                  </div>
                  <span class="index-bottom-ul-li-sum">
                    {{ val.monitorInrement }}
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <div class="analyse-container" style="height: 477px" v-else>
            <div class="analyse-container-img">
              <img src="../../assets/img/views/analyse-img.png" />
              <p>暂无数据</p>
            </div>
          </div>
        </div>
      </div>
      <div class="index-bottom-right">
        <div class="index-cont-title">
          <div class="index-cont-title-left">
            <img src="../../assets/img/views/topqq.png" />
            <span>媒体热榜</span>
          </div>
          <div class="index-cont-title-right">
            <div class="index-cont-title-more" @click="handelMedis">
              <span class="index-more-span">更多</span>
              <img src="../../assets/img/views/more.png" />
            </div>
          </div>
        </div>
        <div class="container index-bottom-cont carousel">
          <el-carousel v-if="carouselData.length > 2" arrow="never" v-loading="loadingMedia" trigger="click"
            height="477px">
            <el-carousel-item v-for="(val, index) in carouselData" :key="index">
              <div class="carousel-ul-li">
                <div style="width: 100%; height: 100%; overflow: hidden">
                  <div class="carousel-name">
                    <img v-if="
                      val.listName == '头条热榜' ||
                      val.listName == '今日头条-全国' ||
                      val.listName == '今日头条'
                    " src="../../assets/img/views/toutiao.png" />
                    <img v-if="
                      val.listName == '抖音热榜' ||
                      val.listName == '抖音热榜（社会）'
                    " src="../../assets/img/views/douyin.png" />
                    <img v-if="val.listName == '百度热榜'" src="../../assets/img/views/baidu.png" />
                    <img v-if="val.listName == '贴吧热议榜'" src="../../assets/img/views/tieba.png" />
                    <img v-if="val.listName == '知乎热榜'" src="../../assets/img/views/zhihu.png" />
                    <img v-if="val.listName == '新浪微博'" src="../../assets/img/views/xl.png" />
                    <img v-if="val.listName == '哔哩哔哩'" src="../../assets/img/views/bl.png" />

                    <span>
                      {{
                        val.listName + (val.areaName ? "-" + val.areaName : "")
                      }}
                    </span>
                  </div>
                  <ul v-if="val.hostList && val.hostList.length != 0" class="carousel-list">
                    <li class="carousel-list-text-li" v-for="(val2, index2) in val.hostList" :key="index2">
                      <span class="carousel-list-text-index">
                        {{ index2 + 1 }}
                      </span>
                      <span class="carousel-list-text-title">
                        {{ val2.title }}
                      </span>
                      <img class="carousel-list-img" v-if="val2.states == '火'" src="../../assets/img/views/huo.png" alt />
                      <img class="carousel-list-img" v-if="val2.states == '热'" src="../../assets/img/views/re.png" alt />
                      <img class="carousel-list-img" v-if="val2.states == '爆'" src="../../assets/img/views/bao.png" alt />
                      <img class="carousel-list-img" v-if="val2.states == '新'" src="../../assets/img/views/xin.png" alt />
                      <!-- <span
                      class="carousel-list-text-read"
                      >{{ val.hostTypeId == 1? val2.heatIndex : val.hostTypeId == 2 ? val2.readVolume:val.hostTypeId == 3 ?val2.readVolume :val.hostTypeId == 4 ? val2.pageviews : val.hostTypeId == 5 ? val2.degreeHeat : val.hostTypeId == 6 ? val2.readVolume :val.hostTypeId == 7 ? val2.degreeHeat :val.hostTypeId == 8 ? val2.degreeHeat :val.hostTypeId == 9 ? val2.degreeHeat : '' }}</span>-->
                      <span class="carousel-list-text-read">
                        {{
                          val2.clicks > 99999
                          ? parseInt((val2.clicks / 10000) * 10) / 10 + "万"
                          : val2.clicks
                        }}
                      </span>
                    </li>
                  </ul>
                  <div class="analyse-container" v-else>
                    <div class="analyse-container-img">
                      <img src="../../assets/img/views/analyse-img.png" />
                      <p>暂无数据</p>
                    </div>
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>

          <el-carousel :initial-index="1" v-else arrow="never" v-loading="loadingMedia" trigger="click" height="477px">
            <el-carousel-item v-for="(val, index) in carouselData" :key="index">
              <div class="carousel-ul-li">
                <div style="width: 100%; height: 100%; overflow: hidden">
                  <div class="carousel-name">
                    <img v-if="
                      val.listName == '头条热榜' ||
                      val.listName == '今日头条-全国' ||
                      val.listName == '今日头条'
                    " src="../../assets/img/views/toutiao.png" />
                    <img v-if="
                      val.listName == '抖音热榜' ||
                      val.listName == '抖音热榜（社会）'
                    " src="../../assets/img/views/douyin.png" />
                    <img v-if="val.listName == '百度热榜'" src="../../assets/img/views/baidu.png" />
                    <img v-if="val.listName == '贴吧热议榜'" src="../../assets/img/views/tieba.png" />
                    <img v-if="val.listName == '知乎热榜'" src="../../assets/img/views/zhihu.png" />
                    <img v-if="val.listName == '新浪微博'" src="../../assets/img/views/xl.png" />
                    <span>
                      {{
                        val.listName + (val.areaName ? "-" + val.areaName : "")
                      }}
                    </span>
                  </div>
                  <ul v-if="val.hostList && val.hostList.length != 0" class="carousel-list">
                    <li class="carousel-list-text-li" v-for="(val2, index2) in val.hostList" :key="index2">
                      <span class="carousel-list-text-index">
                        {{ index2 + 1 }}
                      </span>
                      <span class="carousel-list-text-title">
                        {{ val2.title }}
                      </span>
                      <img class="carousel-list-img" v-if="val2.states == '火'" src="../../assets/img/views/huo.png" alt />
                      <img class="carousel-list-img" v-if="val2.states == '热'" src="../../assets/img/views/re.png" alt />
                      <img class="carousel-list-img" v-if="val2.states == '爆'" src="../../assets/img/views/bao.png" alt />
                      <img class="carousel-list-img" v-if="val2.states == '新'" src="../../assets/img/views/xin.png" alt />
                      <!-- <span
                      class="carousel-list-text-read"
                      >{{ val.hostTypeId == 1? val2.heatIndex : val.hostTypeId == 2 ? val2.readVolume:val.hostTypeId == 3 ?val2.readVolume :val.hostTypeId == 4 ? val2.pageviews : val.hostTypeId == 5 ? val2.degreeHeat : val.hostTypeId == 6 ? val2.readVolume :val.hostTypeId == 7 ? val2.degreeHeat :val.hostTypeId == 8 ? val2.degreeHeat :val.hostTypeId == 9 ? val2.degreeHeat : '' }}</span>-->
                      <span class="carousel-list-text-read">
                        {{
                          val2.clicks > 99999
                          ? parseInt((val2.clicks / 10000) * 10) / 10 + "万"
                          : val2.clicks
                        }}
                      </span>
                    </li>
                  </ul>
                  <div class="analyse-container" v-else>
                    <div class="analyse-container-img">
                      <img src="../../assets/img/views/analyse-img.png" />
                      <p>暂无数据</p>
                    </div>
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import {
  mediaData,
  tonality,
  homePageYuQTrend,
  monitorData,
  topicList
} from "../../api/index/index.js";
// import { topicList } from "../../api/topic/topic";
import { monitorSchemeList } from "../../api/monitoringScheme/index";
import { selectList } from "../../api/hotData/list";
export default {
  data() {
    return {
      loading: false, // 舆情走势echarts loading加载
      loading1: false, //调性分布echarts加载。。
      loading2: false, //媒体分布echarts加载。。
      loadingUl: false, //预警信息列表加载
      loadingTopic: false, //话题统计列表加载
      loadngSupervise: false, //监测方案列表加载
      disables: false, //舆情走势是否禁止点击
      disabless: false, //调性分布是否禁止点击
      disablesss: false, //媒体分布是否禁止点击
      // 模块头部切换
      tabCard: [
        {
          name: "今天",
          type: 1,
        },
        {
          name: "近7天",
          type: 2,
        },
        {
          name: "近30天",
          type: 3,
        },
      ],
      // 预警信息tab切换
      tabCardMessage: [
        {
          name: "监测方案",
          type: 0,
        },
      ],
      // 媒体热榜
      loadingMedia: false,
      warning: [], //预警信息列表
      monitorList: [], //监测方案列表
      conversation: [], // 话题统计列表
      carouselData: [], //媒体热榜列表
      cardActive1: 1,
      cardActive2: 1,
      cardActive3: 1,
      cardActive4: sessionStorage.getItem("cardActive4")
        ? sessionStorage.getItem("cardActive4")
        : "",
      // 媒体分布名称
      mediaName: [],
      // 正面信息
      justData: [],
      // 负面信息
      negativeData: [],
      // 中性信息
      zhongData: [],
      // 全部数据
      tonalityAll: [],
      // 负面数据r
      tonalityFm: [],
      // 其他
      tonalityOr: [],
      // 热榜主键ID
      // hotListIdList: [],
      nowHour: "", //当前小时
      HourList: [], //钟点时间 24小时
      haveData: true,
      noData: false,
    };
  },
  watch: {
    "$store.state.warningInfo": function () {
      setTimeout(() => {
        this.messageList();
      }, 3000);
    },
  },
  methods: {
    // 预警信息跳转详情
    handelPush(val) {
      val.isRead = "1";
      if (this.cardActive4 == 1) {
        let routeUrl = this.$router.resolve({
          path: "/index/accurateInfo",
          query: {
            info: encodeURIComponent(
              JSON.stringify({
                id: val.dataId,
                dataId: val.dataId,
              })
            ),
          },
        });
        window.open(routeUrl.href, "_blank");
      } else if (this.cardActive4 == 2) {
        let routeUrl = this.$router.resolve({
          path: "/index/abroadInfo",
          query: {
            info: encodeURIComponent(
              JSON.stringify({
                id: val.dataId,
                type: "yujing",
                activeName: "first",
              })
            ),
          },
        });
        window.open(routeUrl.href, "_blank");
      } else if (this.cardActive4 == 0) {
        if (val.montype == 5 || val.montype == 4) {
          // 说明是跟评
          window.open(val.mediaLevel);
          return false;
        }
        let routeUrl = this.$router.resolve({
          path: "/index/monitorInfo",
          query: {
            info: encodeURIComponent(
              JSON.stringify({
                montype: val.montype,
                dataId: val.dataId,
                activeName: "first",
                cityId: val.cityId,
              })
            ),
          },
        });
        window.open(routeUrl.href, "_blank");
      }
    },
    tabActive1(val) {
      this.cardActive1 = val.type;
      this.homePageYuQTrend();
    },
    tabActive2(val) {
      this.cardActive2 = val.type;
      if (document.getElementById("echarts2one")) {
        echarts.dispose(document.getElementById("echarts2one"));
      }
      if (document.getElementById("echarts2two")) {
        echarts.dispose(document.getElementById("echarts2two"));
      }
      if (document.getElementById("echarts2three")) {
        echarts.dispose(document.getElementById("echarts2three"));
      }
      this.tonalityAll = [];
      this.tonalityFm = [];
      this.tonalityOr = [];
      this.echarts2();
    },
    tabActive3(val) {
      this.cardActive3 = val.type;
      if (document.getElementById("echarts3")) {
        echarts.dispose(document.getElementById("echarts3"));
      }
      this.echarts3();
    },
    tabActive4(val) {
      this.cardActive4 = val.type;
      sessionStorage.setItem("cardActive4", val.type);
      this.messageList();
    },
    // 舆情走势数据
    async homePageYuQTrend() {
      if (this.disables) {
        return;
      }
      this.loading = true;
      let xData = [];
      let dataLists = [];
      let dataList = [];
      let sentiment_time = [];
      let objbar1Num = 0; //正面线的和累计
      let objbar2Num = 0; //中面线的和累计
      let objbar3Num = 0; //负面线的和累计
      let objline1 = {
        name: "正面",
        type: "line",
        symbol: "none",
        itemStyle: {
          color: "#5BE6D9",
        },
        yAxisIndex: 1,
        data: [],
      }; // 正面折线
      let objline2 = {
        name: "中性",
        type: "line",
        symbol: "none",
        itemStyle: {
          color: "#6398FF",
        },
        yAxisIndex: 1,
        data: [],
      }; //中性折线
      let objline3 = {
        name: "负面",
        type: "line",
        symbol: "none",
        itemStyle: {
          color: "#ff0000",
        },
        yAxisIndex: 1,
        data: [],
      }; //负面折线
      let objbar1 = {
        name: "正面",
        type: "bar",
        symbol: "none",
        itemStyle: {
          color: "#5BE6D9",
        },
        barWidth: 6,
        data: [],
      }; // 正面柱状
      let objbar2 = {
        name: "中性",
        type: "bar",
        symbol: "none",
        itemStyle: {
          color: "#6398FF",
        },
        barWidth: 6,
        data: [],
      }; //中性柱状
      let objbar3 = {
        name: "负面",
        type: "bar",
        symbol: "none",
        itemStyle: {
          color: "#ff0000",
        },
        barWidth: 6,
        data: [],
      }; //负面柱状
      this.disables = true;
      const res = await homePageYuQTrend({
        organId: this.$store.state.user.organizationId,
        type: this.cardActive1,
      });
      if (res.data.data && res.data.data.length != 0) {
        dataList = res.data.data;
      }
      this.disables = false;
      // 如果是今天
      if (this.cardActive1 == 1) {
        sentiment_time = this.HourList;
        if (dataList.length != 0) {
          sentiment_time = this.getDateList(dataList, sentiment_time, "1");
        }
      } else if (this.cardActive1 == 2) {
        sentiment_time = this.getTimeSentiment(6, 7);

        if (dataList.length != 0) {
          sentiment_time = this.getDateList(dataList, sentiment_time, "2");
        }
        //如果是七天
      } else {
        sentiment_time = this.getTimeSentiment(29, 30);
        //如果是30天
        if (dataList.length != 0) {
          sentiment_time = this.getDateList(dataList, sentiment_time, "2");
        }
      }
      // 4. 拿分配好数据结构的所有时间段的数据  每12小时一取
      sentiment_time.forEach((item) => {
        item.time = item.time.replace(" ", "\n");
        xData.push(item.time);
        // 5.把所有类型的数据 导出数组
        objline1.data.push(item.data0); //正面的数据
        objline2.data.push(item.data1); //中性
        objline3.data.push(item.data2); //负面
      });
      objbar1 = this.getNumSentiment(objbar1, objline1.data, objbar1Num);
      objbar2 = this.getNumSentiment(objbar2, objline2.data, objbar2Num);
      objbar3 = this.getNumSentiment(objbar3, objline3.data, objbar3Num);
      dataLists.push(objline1, objline2, objline3, objbar1, objbar2, objbar3);
      this.echarts1(dataLists, xData);
      this.loading = false;
    },
    //舆情走势
    echarts1(dataList, timer) {
      document.getElementById("echarts1").removeAttribute("_echarts_instance_");
      let myChart = echarts.init(document.getElementById("echarts1"));
      let option = {
        grid: {
          left: 25,
          right: "5%",
          bottom: "15%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            return (
              params[0].name +
              "<br/>" +
              params[0].marker +
              params[0].seriesName +
              "：" +
              params[0].data +
              "（增量）" +
              "<br>" +
              params[1].marker +
              params[1].seriesName +
              "：" +
              params[1].data +
              "（增量）" +
              "<br>" +
              params[2].marker +
              params[2].seriesName +
              "：" +
              params[2].data +
              "（增量）" +
              "<br>" +
              params[3].marker +
              params[3].seriesName +
              "：" +
              params[3].data +
              "（总量）" +
              "<br>" +
              params[4].marker +
              params[4].seriesName +
              "：" +
              params[4].data +
              "（总量）" +
              "<br>" +
              params[5].marker +
              params[5].seriesName +
              "：" +
              params[5].data +
              "（总量）"
            );
          },
        },
        dataZoom: [
          {
            type: "inside",
            // start: 90,
            // end: 100
          },
          {
            startValue: timer[0],
          },
        ],
        xAxis: {
          type: "category",
          data: timer,
          borderColor: "#D8D8D8",
          axisLine: {
            lineStyle: {
              color: "#B8BCC3",
            },
          },
          axisTick: {
            show: false
          },
        },
        yAxis: [
          {
            name: "总量",
            type: "value",
            axisLine: {
              lineStyle: {
                color: "#CCCCCC",
                fontSize: 12,
              },
            },
            minInterval: 1,
            boundaryGap: ["0%", "50%"],
            splitLine: {
              lineStyle: {
                color: "#E5E5E5",
                type: "dashed",
              },
            },
          },
          {
            name: "增量",
            type: "value",
            axisLine: {
              lineStyle: {
                color: "#CCCCCC",
                fontSize: 12,
              },
            },
            minInterval: 1,
            splitLine: {
              lineStyle: {
                color: "#E5E5E5",
                type: "dashed",
              },
            },
          },
        ],
        series: dataList,
      };
      myChart.setOption(option);
      // 注释掉的首页舆情走势跳转精准舆情功能，因两边时间参数不一致注释
      // myChart.getZr().on('click', params => {
      //   const pointInPixel = [params.offsetX, params.offsetY];
      //   if (myChart.containPixel('grid', pointInPixel)) {
      //     let time;
      //     let data = {};
      //     let taday = new Date();
      //     const xIndex = myChart.convertFromPixel({ seriesIndex: 0 }, [params.offsetX, params.offsetY])[0];
      //     time = option.xAxis.data[xIndex];
      //     if(/^\d\d-\d\d\n\d\d:\d\d$/.test(time)){
      //       let newTime = new Date(taday.getFullYear()+"-"+time);
      //       if(newTime>taday){
      //         newTime = new Date(taday.getFullYear()-1+"-"+time);
      //         data.startTimeStr = this.$calcu.timeCycle(newTime.getTime()-30*60*1000,"yyyy-MM-ddTHH:mm:ss")+"+08:00:00";
      //         data.endTimeStr = this.$calcu.timeCycle(newTime.getTime()+30*60*1000,"yyyy-MM-ddTHH:mm:ss")+"+08:00:00";
      //       }else{
      //         data.startTimeStr = this.$calcu.timeCycle(newTime.getTime()-30*60*1000,"yyyy-MM-ddTHH:mm:ss")+"+08:00:00";
      //         data.endTimeStr = this.$calcu.timeCycle(newTime.getTime()+30*60*1000,"yyyy-MM-ddTHH:mm:ss")+"+08:00:00";
      //       }
      //     }else{
      //       data.startTimeStr = this.$calcu.timeCycle(taday.setHours(time.slice(0,2)*1,0,0)-30*60*1000,"yyyy-MM-ddTHH:mm:ss")+"+08:00:00";
      //       data.endTimeStr = this.$calcu.timeCycle(taday.setHours(time.slice(0,2)*1,0,0)+30*60*1000,"yyyy-MM-ddTHH:mm:ss")+"+08:00:00";
      //     }
      //     this.$router.push({
      //       path:"/sentiment",
      //       query:{
      //         info:encodeURIComponent(JSON.stringify(data))
      //       }
      //     });
      //     // let routeUrl = this.$router.resolve({
      //     //   path:"/sentiment",
      //     //   query:{
      //     //     info:encodeURIComponent(JSON.stringify(data))
      //     //   }
      //     // });
      //     // window.open(routeUrl.href,"_blank");
      //   }
      // });
      window.onresize = function () {
        myChart.resize();
      };
    },
    //调性分布
    async echarts2() {
      if (this.disabless) {
        return;
      }
      this.loading1 = true;
      this.disabless = true;
      const res = await tonality({
        organId: this.$store.state.user.organizationId,
        type: this.cardActive2,
      });
      this.disabless = false;
      if (res != undefined) {
        // 全部数据
        if (res.data.data.all && res.data.data.all.length != 0) {
          res.data.data.all.forEach((item) => {
            this.tonalityAll.push(this.tonalityData(item));
          });
          setTimeout(() => {
            this.echartsone(this.tonalityAll);
          }, 500);
        } else {
          this.tonalityAll = [];
        }
        // 负面
        if (res.data.data.negative && res.data.data.negative.length != 0) {
          res.data.data.negative.forEach((item) => {
            this.tonalityFm.push(this.tonalityData(item));
          });
          setTimeout(() => {
            this.echartstwo(this.tonalityFm);
          }, 500);
        } else {
          this.tonalityFm = [];
        }
        // 非负面
        if (res.data.data.other && res.data.data.other.length != 0) {
          res.data.data.other.forEach((item) => {
            this.tonalityOr.push(this.tonalityData(item));
          });
          setTimeout(() => {
            this.echartsthree(this.tonalityOr);
          }, 500);
        } else {
          this.tonalityOr = [];
        }
      }
      this.loading1 = false;
    },
    // 调性分布图1
    echartsone() {
      let myChart = echarts.init(document.getElementById("echarts2one"));
      myChart.clear();
      let option = {
        title: [
          {
            text: "全部",
            left: "50%",
            top: "85%",
            textAlign: "center",
            textStyle: {
              // color: "#B8BCC3",
              color: "#000",
              fontSize: 14,
              fontWeight: "normal",
            },
          },
        ],
        label: {
          formatter: "{d}%",
        },
        tooltip: {},
        series: [
          {
            name: "全部",
            type: "pie",
            center: ["50%", "55%"],
            radius: 80,
            minAngle: 10,
            data: this.tonalityAll,
          },
        ],
      };
      myChart.setOption(option);
      window.onresize = function () {
        myChart.resize();
      };
    },
    // 调性分布图2
    echartstwo() {
      let myChart = echarts.init(document.getElementById("echarts2two"));
      myChart.clear();
      let option = {
        title: [
          {
            text: "负面",
            left: "49%",
            top: "85%",
            textAlign: "center",
            textStyle: {
              // color: "#B8BCC3",
              color: "#000",
              fontSize: 14,
              fontWeight: "normal",
            },
          },
        ],
        label: {
          formatter: "{d}%",
        },
        tooltip: {},
        series: [
          {
            name: "负面",
            type: "pie",
            center: ["50%", "55%"],
            radius: 80,
            minAngle: 10,
            data: this.tonalityFm,
          },
        ],
      };
      myChart.setOption(option);
      window.onresize = function () {
        myChart.resize();
      };
    },
    // 调性分布图3
    echartsthree() {
      let myChart = echarts.init(document.getElementById("echarts2three"));
      myChart.clear();

      let option = {
        title: [
          {
            text: "非负面（正面+中性)",
            left: "50%",
            top: "85%",
            textAlign: "center",
            textStyle: {
              // color: "#B8BCC3",
              color: "#000",
              fontSize: 14,
              fontWeight: "normal",
            },
          },
        ],
        label: {
          formatter: "{d}%",
        },
        tooltip: {},
        series: [
          {
            name: "非负面（正面+中性)",
            type: "pie",
            center: ["50%", "55%"],
            radius: 80,
            minAngle: 10,
            data: this.tonalityOr,
          },
        ],
      };
      myChart.setOption(option);
      window.onresize = function () {
        myChart.resize();
      };
    },
    //媒体分布
    async echarts3() {
      if (this.disablesss) {
        return;
      }
      this.loading2 = true;
      this.mediaName = [];
      this.justData = [];
      this.zhongData = [];
      this.negativeData = [];
      this.disablesss = true;
      const res = await mediaData({
        organId: this.$store.state.user.organizationId,
        type: this.cardActive3,
      });
      this.disablesss = false;
      if (res.data.data != undefined) {
        res.data.data.forEach((item) => {
          this.mediaName.push(item.mediaLink);
          this.justData.push(item.num0);
          this.zhongData.push(item.num1);
          this.negativeData.push(item.num2);
        });
      }
      this.loading2 = false;
      if (
        this.mediaName.length != 0 ||
        this.justData.length != 0 ||
        this.zhongData.length != 0 ||
        this.negativeData.length != 0
      ) {
        setTimeout(() => {
          let myChart = echarts.init(document.getElementById("echarts3"));
          let option = {
            legend: {
              show: true,
              top: 16,
              itemHeight: 6,
              itemWidth: 6,
              itemGap: 60,
              data: [
                {
                  name: "正面",
                  icon: "circle",
                  textStyle: {
                    color: "#5A6474",
                    fontSize: 14,
                  },
                },
                {
                  name: "中性",
                  icon: "circle",
                  textStyle: {
                    color: "#5A6474",
                    fontSize: 14,
                  },
                },
                {
                  name: "负面",
                  icon: "circle",
                  textStyle: {
                    color: "#5A6474",
                    fontSize: 14,
                  },
                },
              ],
            },
            tooltip: {
              trigger: "axis",
            },
            grid: {
              left: 16,
              right: "1%",
              bottom: 10,
              containLabel: true,
            },
            xAxis: {
              type: "category",
              data: this.mediaName,
              borderColor: "#D8D8D8",
              axisLine: {
                lineStyle: {
                  color: "#B8BCC3",
                },
              },
              axisTick: {
                show: false
              },
              axisLabel: {
                //x轴文字的配置
                show: true,
                interval: 0, //使x轴文字显示全
                color: "#000",
              },
            },
            yAxis: [
              {
                type: "value",
                axisLine: {
                  lineStyle: {
                    color: "#CCCCCC",
                    fontSize: 12,
                  },
                },
                minInterval: 1,
                boundaryGap: ["0%", "50%"],
                splitLine: {
                  lineStyle: {
                    color: "#E5E5E5",
                    type: "dashed",
                  },
                },
                // minInterval: 1, //将刻度的最小间距设置为1
                // max: arrList * 2
              },
            ],
            series: [
              {
                name: "正面",
                type: "bar",
                symbol: "none",
                itemStyle: {
                  color: "#5BE6D9",
                },
                barWidth: 6,
                data: this.justData,
              },
              {
                name: "中性",
                type: "bar",
                symbol: "none",
                itemStyle: {
                  color: "#6398FF",
                },
                barWidth: 6,
                data: this.zhongData,
              },
              {
                name: "负面",
                type: "bar",
                symbol: "none",
                itemStyle: {
                  color: "#FF70AF",
                },
                barWidth: 6,
                data: this.negativeData,
              },
            ],
          };
          myChart.setOption(option);
          window.onresize = function () {
            myChart.resize();
          };
        }, 500);
      } else {
        this.mediaName = [];
        this.justData = [];
        this.zhongData = [];
        this.negativeData = [];
      }
    },
    // 话题统计
    async topicList() {
      this.loadingTopic = true;
      const res = await topicList({
        organId: this.$store.state.user.organizationId,
      });
      if (res.data.rows && res.data.rows.length != 0) {
        this.conversation = res.data.rows.slice(0, 7);
      }
      this.conversation.forEach((item) => {
        if (item.startTime) {
          item.startTime = item.startTime.slice(0, 10);
        }
        if (item.upateTime) {
          item.upateTime = item.upateTime.slice(0, 10);
        }
      });
      this.loadingTopic = false;
    },
    //  热榜模块
    async hotListInfo() {
      this.carouselData = [];
      const res = await selectList({});
      if (res.data.rows) {
        this.carouselData = res.data.rows;
        if (this.carouselData.length == 2) {
          // element ui 轮播图组件两个就会两边摆，所以在两边放入
          this.carouselData.push(this.carouselData[0]);
          this.carouselData.push(this.carouselData[1]);
        }
      }
    },
    // 预警信息
    async messageList() {
      this.loadingUl = true;
      let data = {
        orgaId: this.$store.state.user.organizationId,
      };
      let url = `?pageSize=6&pageNum=1`;
      if (this.cardActive4 == 0) {
        //监测方案
        data.dataType = 2;
        data.sourceType = 1;
      } else if (this.cardActive4 == 1) {
        //精准舆情
        data.dataType = 0;
        data.sourceType = 0;
      } else if (this.cardActive4 == 2) {
        //境外
        data.dataType = 1;
        data.sourceType = 1;
      }
      let res = await monitorData(data, url);
      if (res.data.rows && res.data.rows.length != 0) {
        this.warning = res.data.rows;
        this.warning.forEach((item) => {
          item.releaseTime = item.releaseTime.slice(0, 19).replace("T", " ");
        });
      } else {
        this.warning = [];
      }
      this.loadingUl = false;
    },
    // 监测方案
    async monitorSchemeList() {
      this.loadngSupervise = true;
      const res = await monitorSchemeList({
        organId: this.$store.state.user.organizationId,
        organType: this.$store.state.user.organizationTypeId + "",
      });
      if (res.data.data && res.data.data.length != 0) {
        this.monitorList = res.data.data.slice(0, 7);
      }
      this.loadngSupervise = false;
    },
    // 全部话题
    handelTopic() {
      this.$router.push({
        path: "/topics/index",
      });
    },
    // 方案
    handelProgramme() {
      this.$router.push({
        path: "/monitoringScheme/index/Territory",
      });
    },
    // 方案
    handelProgrammeDetail(item) {
      if (item.projectType == "0") {
        sessionStorage.setItem("names", "境内");
        sessionStorage.setItem("defultName", "territory");
        if (item.monitorId) {
          sessionStorage.setItem("defaultId", item.monitorId);
        } else {
          sessionStorage.setItem("defaultId", item.pmonitorId);
        }
        this.$router.push({
          path: "/monitoringScheme/index/Territory",
          query: {
            data: encodeURIComponent(JSON.stringify(item)),
          },
        });
      } else if (item.projectType == "1") {
        this.$router.push({
          path: "/monitoringScheme/index/abroad",
          query: {
            data: encodeURIComponent(JSON.stringify(item)),
          },
        });
        sessionStorage.setItem("names", "境外");
        sessionStorage.setItem("defultName", "abroad");
      } else {
        this.$router.push({
          path: "/monitoringScheme/index/Follow",
          query: {
            data: encodeURIComponent(JSON.stringify(item)),
          },
        });
        sessionStorage.setItem("names", "跟评");
        sessionStorage.setItem("defultName", "Follow");
      }
    },
    handelMedis() {
      //更多话题
      this.$router.push({
        path: "/hotData/index",
      });
    },
    // 话题详情跳转
    handle(row) {
      this.$router.push({
        path: "/topics/index/topicList/topicListDetails",
        query: {
          topicId: row.topicId,
          id: row.id,
          type: "history", //首页话题跳详情，history 为历史话题
          topicType: row.topicType,
        },
      });
    },
    //获取当前时间 24小时
    getHour() {
      var h = new Date().getHours();
      var m = new Date().getMinutes();
      if (m > 1) {
        h = h + 1;
      }
      return h;
    },
    // 舆情走势时间计算
    getTimeSentiment(data, num) {
      let DataList = [];
      let myDate = new Date(); // 获取今天日期
      myDate.setDate(myDate.getDate() - data);
      let dateArray = [];
      let flag = 1;
      let getMonth;
      let getDate;
      for (let i = 0; i < num; i++) {
        getMonth =
          myDate.getMonth() + 1 < 10
            ? "0" + (myDate.getMonth() + 1)
            : myDate.getMonth() + 1;
        getDate =
          myDate.getDate() < 10 ? "0" + myDate.getDate() : myDate.getDate();
        dateArray.push(getMonth + "-" + getDate);
        myDate.setDate(myDate.getDate() + flag);
      }
      let nowHours = myDate.getHours();
      let nowDate = dateArray.length - 1;
      for (let i = 0; i < dateArray.length; i++) {
        for (let y = 0; y < 24; y++) {
          if (nowDate == i) {
            if (nowHours + 1 == y) {
              break;
            }
          }
          if (y <= 9) {
            DataList.push({
              time: dateArray[i] + " " + "0" + y + ":00",
              data0: 0,
              data1: 0,
              data2: 0,
            });
          } else {
            DataList.push({
              time: dateArray[i] + " " + y + ":00",
              data0: 0,
              data1: 0,
              data2: 0,
            });
          }
        }
      }
      return DataList;
    },
    // 用来计算舆情走势累加和
    getNumSentiment(listType, list, num) {
      // 柱形统计图是递增的
      for (let i = 0; i < list.length; i++) {
        if (i == 0) {
          listType.data.push(list[i]);
        } else {
          num += list[i - 1];
          listType.data.push(list[i] + num);
        }
      }
      return listType;
    },
    // 舆情走势时间数据替换
    getDateList(dataList, sentiment_time, str) {
      let times;
      dataList.forEach((item) => {
        sentiment_time.forEach((ite) => {
          if (item.showTime) {
            if (str == "1") {
              times = item.showTime.split(" ")[1];
            } else {
              times = item.showTime.slice(5);
            }
            if (item.tonalState == 0) {
              if (times == ite.time) {
                ite.data0 = item.totalCount;
              }
            } else if (item.tonalState == 1) {
              if (times == ite.time) {
                ite.data1 = item.totalCount;
              }
            } else {
              if (times == ite.time) {
                ite.data2 = item.totalCount;
              }
            }
          }
        });
      });
      return sentiment_time;
    },
    // 调性分布图形
    tonalityData(item) {
      let obj = {
        value: item.num,
        name: item.mediaLevel,
        itemStyle: {
          color:
            item.mediaLevel == "全国"
              ? "#2e59ec"
              : item.mediaLevel == "省级"
                ? "#009cfc"
                : item.mediaLevel == "市级"
                  ? "#00daba"
                  : item.mediaLevel == "区县级"
                    ? "#ffc75c"
                    : item.mediaLevel == "其他"
                      ? "#a0a5b8"
                      : "",
        },
      };
      return obj;
    },
  },
  created() {
    // let res = a.filter(e => !b.some(e2 => e2.id === e.id));

    let _index;
    if (this.$store.state.user.scope[0]) {
      this.tabCardMessage.unshift({
        name: "精准舆情",
        type: 1,
      });
    }
    if (this.$store.state.user.scope[1]) {
      this.tabCardMessage.forEach((item, index) => {
        if (item.name == "监测方案") {
          _index = index;
        }
      });
      this.tabCardMessage.splice(_index, 0, {
        name: "境外敏感",
        type: 2,
      });
    }
    // this.tabCardMessage.push({
    //   name: "实时信息",
    //   type: 3,
    // });
    this.cardActive4 = sessionStorage.getItem("cardActive4")
      ? sessionStorage.getItem("cardActive4")
      : this.tabCardMessage[0].type;
    this.topicList();
    this.messageList();
    this.monitorSchemeList();
    // 舆情走势数据
    this.homePageYuQTrend();
    this.nowHour = this.getHour();
    //钟点时间 24小时
    let hoursObj = {};
    for (let i = 0; i <= 23; i++) {
      if (this.nowHour == i) {
        break;
      }
      hoursObj = i;
      hoursObj = hoursObj < 10 ? "0" + hoursObj : hoursObj;
      this.HourList.push({
        time: hoursObj + ":00",
        data0: 0,
        data1: 0,
        data2: 0,
      });
    }
    window.addEventListener("setItem", () => {
      this.messageList();
    });
  },
  mounted() {
    this.echarts2();
    this.echarts3();
    //获取推送详情
    this.hotListInfo();
  },
};
</script>

<style scoped>
.index {
  padding: 24px 40px 40px;
  width: 100%;
  transition: all 0.3s;
}

.index.max-index {
  padding: 24px 106px 106px;
}

.index .index-top {
  width: 100%;
  height: 388px;
  display: flex;
  justify-content: space-between;
}

.index-bottom-right .analyse-container-img img {
  width: 100%;
  height: 100%;
}

.index .index-top-left {
  position: relative;
  width: 57%;
  height: 100%;
}

.index .index-top-right {
  width: 668px;
  height: 100%;
}

.index .index-cont-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.index .index-cont-title-left {
  display: flex;
  align-items: center;
}

.index .index-cont-title-left img {
  position: relative;
  top: 2px;
}

.index .index-cont-title-left span {
  font-size: 20px;
  color: #333333;
  font-weight: bold;
}

.index .index-top-cont {
  width: 100%;
  height: 344px;
  margin-top: 16px;
  overflow: hidden;
}

.index .index-center {
  margin-top: 24px;
  height: 394px;
  display: flex;
  justify-content: space-between;
}

.index .index-center-cont {
  width: 100%;
  height: 352px;
  margin-top: 16px;
}

.index .index-cont-title-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.index .index-cont-title-card {
  height: 24px;
  padding: 0 10px;
  background: #fff;
  border-radius: 4px;
  font-size: 14px;
  /* color: #999999; */
  color:#000;
  text-align: center;
  line-height: 24px;
  margin-left: 16px;
  cursor: pointer;
}

.index .index-cont-title-card.active {
  background: #2e59ec;
  color: #ffffff;
}

.index .index-cont-title-more {
  cursor: pointer;
  padding-right: 9px;
}

.index .index-more-span {
  font-size: 14px;
  color: #2e59ec;
  margin-right: 5px;
  font-weight: bold;
}

.index .index-bottom {
  width: 100%;
  height: 502px;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
}

.index .index-bottom-left {
  width: 702px;
  height: 100%;
}

.index .index-bottom-center {
  width: 512px;
  height: 100%;
}

.index .index-bottom-right {
  width: 350px;
  height: 100%;
}

.index .index-bottom-cont {
  height: 477px;
  margin-top: 16px;
  width: 100%;
}

.index .index-ul {
  width: 100%;
  height: 100%;
  padding: 16px;
  overflow: hidden;
}

.index .index-ul-li {
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px;
  cursor: pointer;
}

.index-ul-li-box {
  display: flex;
  align-items: center;
}

.index .index-ul-li:hover {
  background: #f4f7fd;
}

.index .index-ul-li img {
  margin-right: 16px;
  position: relative;
  top: 1px;
}

.index .index-ul-li-title {
  line-height: 24px;
  width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: #5a6474;
  margin-right: 30px;
}

.index .index-ul-li-comment {
  width: 70px;
  font-size: 14px;
  color: #5a6474;
  margin-right: 38px;
}

.index .index-ul-li-comment span {
  color: #2e59ec;
}

.index .index-ul-li-time {
  font-size: 14px;
  color: #5a6474;
}

.index .index-bottom-ul {
  width: 100%;
  height: 100%;
  padding: 16px;
}

.index .index-bottom-ul-li {
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  cursor: pointer;
}

.index .index-bottom-ul-li:hover {
  background-color: #f4f7fd;
}

.index-bottom-ul-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.index .index-bottom-ul-li-title {
  margin-left: 8px;
  font-size: 14px;
  color: #5a6474;
  width: 208px;
  height: 24px;
  line-height: 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.index .index-bottom-ul-li-time {
  font-size: 14px;
  color: #5a6474;
  width: 172px;
  height: 24px;
  line-height: 24px;
  text-align: left;
}

.index .index-bottom-ul-li-sum {
  padding: 0 5px;
  font-size: 14px;
  color: #5a6474;
}

.index .index-bottom-ul-li-up {
  font-size: 14px;
  color: #02bc7c;
}

.index .index-bottom-ul-li-up img {
  position: relative;
  top: -1px;
}

.index .carousel {
  overflow: hidden;
  position: relative;
}

.index .carousel-ul {
  height: 100%;
  position: absolute;
  left: 0;
}

.index .carousel-ul-li {
  width: 350px;
  height: 100%;
  padding: 24px;
  float: left;
}

.index .carousel-name {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.index .carousel-name span {
  font-size: 16px;
  color: #333333;
  font-weight: bold;
  margin-left: 8px;
}

.index .carousel-list {
  margin-top: 24px;
  width: 100%;
}

.index .carousel-list-li {
  height: 50px;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.index .carousel-list-li+.carousel-list-li {
  margin-top: 24px;
}

.index .carousel-list-li img {
  margin-right: 10px;
  width: 50px;
  height: 50px;
}

.index .carousel-list-text p {
  font-size: 14px;
  color: #333333;
  width: 205px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.index .carousel-list-text p+p {
  margin-top: 8px;
  font-size: 12px;
  color: #999999;
}

.index .carousel-list-text-index {
  font-size: 14px;
  color: #999;
  font-weight: bold;
  margin-right: 11px;
}

.index .carousel-list-text-li:nth-child(1) .carousel-list-text-index {
  color: #d0021b;
}

.index .carousel-list-text-li:nth-child(2) .carousel-list-text-index {
  color: #ff2a39;
}

.index .carousel-list-text-li:nth-child(3) .carousel-list-text-index {
  color: #ff6a2c;
}

.index .carousel-list-text-title {
  font-size: 14px;
  color: #5a6474;
  width: 220px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 5px;
}

.index .carousel-list-text-read {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  color: #b8bcc3;
  width: 70px;
  text-align: right;
}

.index .carousel-list-text-li {
  display: flex;
}

.index .carousel-list-text-li+.carousel-list-text-li {
  margin-top: 19px;
}

.index .container-tab {
  width: 100%;
  position: absolute;
  bottom: 10px;
  text-align: center;
}

.index .container-tab-dian {
  width: 4px;
  height: 4px;
  background: #b8bcc3;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.index .container-tab-dian+.container-tab-dian {
  margin-left: 8px;
}

.index .container-tab-dian.active {
  background: #5a6474;
}

.noDatas {
  width: 100%;
  height: 100%;
  line-height: 344px;
  text-align: center;
}

.noClick {
  pointer-events: none;
}

.analyse-container {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 16px;
  text-align: center;
}

.analyse-container-img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.analyse-container-img p {
  font-size: 14px;
  color: #999999;
}

.carousel-list-img {
  width: 19px;
}

.trend-title {
  position: absolute;
  left: 50%;
  top: 60px;
  z-index: 9;
  margin-left: -250px;
  display: flex;
  color: #5a6474;
  width: 500px;
  justify-content: space-between;
}

.trend-title>div {
  width: 230px;
  display: flex;
  justify-content: space-evenly;
}

.box-color {
  width: 6px;
  height: 6px;
  margin-right: 6px;
}

.box-color-line {
  display: inline-block;
  width: 15px;
  height: 2px;
  margin-right: 6px;
}

.box-color-box {
  display: flex;
  align-items: center;
}

.index-center-cont-title {
  color: #5a6474;
  position: absolute;
  left: 50%;
  top: 60px;
  margin-left: -250px;
  z-index: 9;
  display: flex;
  justify-content: space-evenly;
  width: 455px;
}

.index-center-cont-title div {
  display: flex;
  align-items: center;
}

.index-center-circular {
  width: 6px;
  height: 6px;
  margin-right: 8px;
  border-radius: 50%;
}

.comment-weidu {
  color: #ea3342;
  display: flex;
  align-items: center;
}

.yidu {
  color: #02bc7c;
}
</style>
