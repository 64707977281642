import request from '@/api/request.js'
// 这里面存关于热榜的请求
export function hotList(data) {
  return request({
    url: 'api/msgquery/hotList/selectSystemHostList',
    method: 'post',
    data: data
  })
}
// 修改保存
export function changeInfo(data) {
  return request({
    url: 'api/msgquery/hotList/edit',
    method: 'post',
    data: data
  })
}
// 换一换按钮
export function changeData(data, url) {
  return request({
    url: 'api/msgquery/hotList/selectSingleList' + url,
    method: 'post',
    data: data
  })
}
// 回显获取详情
export function getListInfo(data) {
  return request({
    url: 'api/msgquery/hotList/querySet',
    method: 'post',
    data: data
  })
}
// 查询首页列表
export function hotListInfo(data) {
  return request({
    url: 'api/msgquery/search/selectList',
    method: 'post',
    data: data
  })
}
// 项目首页列表
export function indexHotList(data) {
  return request({
    url: 'api/msgquery/search/selectIndexList',
    method: 'post',
    data: data
  })
}
// ios热榜列表
export function selectHostList(data) {
  return request({
    url: 'api/msgquery/hotList/selectHostList',
    method: 'post',
    data: data
  })
}
// 列表模块

export function selectList(data) {
  return request({
    url: 'api/msgquery/hotList/selectList',
    method: 'post',
    data: data
  })
}
//热榜分布查询
export function todayHotRank(data) {
  return request({
    url: 'api/msgquery/hotRankArea/todayHotRank',
    method: 'post',
    data: data
  })
}
//热榜分布时间轴查询
export function getTimeAxis(data) {
  return request({
    url: 'api/msgquery/hotRankArea/timeMenu',
    method: 'post',
    data: data
  })
}
//热榜分布时间轴查询
export function selectlocal(data) {
  return request({
    url: 'api/organization/organization/selectlocal?organId=' + data.organId,
    method: 'get',
  })
}